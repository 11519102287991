import React from 'react';

const Donate = () => {
  return (
    <div>
      Donate
    </div>
  );
};

export default Donate;